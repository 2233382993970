<template>
  <v-scale-transition>
    <wide-panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        flat
        prominent
      >
        <v-autocomplete
          v-model="dealer"
          :hint="dealerInfo"
          :loading="dealerLoading"
          :items="dealerList"
          :search-input.sync="search"
          item-text="dealer"
          label="Start typing and select dealerID"
          prepend-icon="mdi-account-tie"
          persistent-hint
          hide-no-data
          return-object
        >
          <template #item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-tile-content v-text="data.dealer" />
            </template>
            <template v-else>
              <v-list-tile-content>
                <v-list-tile-title v-html="data.item.dealer" />
                <v-list-tile-sub-title>
                  {{ data.item.msisdn + ': ' + data.item.name }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </template>
          </template>
        </v-autocomplete>
        <v-spacer />
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template #activator="{ on }">
            <v-btn
              :disabled="!dealer"
              color="primary"
              dark
              class="mb-2"
              v-on="on"
            >
              Insert New Number
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add New Number</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="addedItem.msisdn"
                      label="Msisdn"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-alert
              :value="alert"
              type="error"
            >
              {{ alertMessage }}
            </v-alert>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="blue darken-1"
                flat
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="save"
              >
                Add New Number
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="msisdns"
        class="elevation-1"
      >
        <template #items="props">
          <td class="text-xs-left">
            {{ props.item.introducernewNum }}
          </td>
          <td class="text-xs-left">
            {{ props.item.namenewNum }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdnnewNum }}
          </td>
          <td class="text-xs-left">
            {{ props.item.nricnewNum }}
          </td>
          <td class="text-xs-left">
            {{ props.item.subscriptionDatenewNum }}
          </td>
          <td class="text-xs-left">
            {{ props.item.plannewNum }}
          </td>
          <td class="justify-center layout px-0">
            <v-icon
              small
              @click="deleteItem(props.item)"
            >
              delete
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="executeDialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            :disabled="disabled"
            color="primary"
            dark
            class="mb-2"
            v-on="on"
          >
            Execute Change DREG
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Execute Change DREG</span>
          </v-card-title>

          <v-alert
            :value="true"
            :type="executeAlertType"
            v-html="executeAlertMessage"
          />

          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeDialog = false"
            >
              {{ executeAlertType === 'info' ? 'Cancel' : 'Close' }}
            </v-btn>
            <v-btn
              v-show="executeAlertType === 'info'"
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              flat
              @click="executeChangeDreg"
            >
              Execute
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </wide-panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams, createPostParams } from '@/rest'

const pageTitle = 'Change DREG'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data: () => ({
    dialog: false,
    title: pageTitle,
    alert: false,
    alertMessage: null,
    dealer: null,
    dealerList: [],
    dealerInfo: 'No dealer selected.',
    search: null,
    searchId: null,
    source: null,
    dealerLoading: false,
    loading: false,
    executeDialog: false,
    executeAlertType: 'info',
    executeAlertMessage: null,
    breadcrumbs: [
      {
        text: 'Technical Kits', disabled: false, to: '/technical/technical_kits',
      },
      {
        text: pageTitle, disabled: true,
      },
    ],
    headers: [
      {
        text: 'Introducer',
        align: 'left',
        sortable: true,
        value: 'introducer',
      },
      {
        text: 'Name',
        align: 'left',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Msisdn',
        align: 'left',
        sortable: true,
        value: 'msisdn',
      },
      {
        text: 'Nric',
        align: 'left',
        sortable: true,
        value: 'nric',
      },
      {
        text: 'Subscription Date',
        align: 'left',
        sortable: true,
        value: 'subscriptionDate',
      },
      {
        text: 'Plan',
        align: 'left',
        sortable: true,
        value: 'plan',
      },
      {
        text: 'Actions',
        align: 'center',
        value: 'msisdn',
        sortable: false,
      },
    ],
    msisdns: [],
    addedItem: {
      idnewNum: null,
      introducernewNum: null,
      namenewNum: null,
      msisdnnewNum: null,
      nricnewNum: null,
      subscriptionDatenewNum: null,
      plannewNum: null,
    },
    defaultItem: {
      idnewNum: null,
      introducernewNum: null,
      namenewNum: null,
      msisdnewNum: null,
      nricnewNum: null,
      subscriptionDatenewNum: null,
      plannewNum: null,
    },
  }),
  computed: {
    disabled: function () {
      return this.msisdns.length === 0 || !this.dealer
    },
  },
  watch: {
    executeDialog (val) {
      if (val) {
        this.executeAlertType = 'info'
        this.executeAlertMessage = '<div>Click <strong>EXECUTE</strong> only when you are sure that the old number and new number details are correct.</div>'
      }
    },

    dialog (val) {
      if (val) {
        this.alert = false
        this.alertMessage = null
      } else {
        this.close()
      }
    },

    dealer: function (dealer) {
      if (dealer) {
        this.dealerInfo = dealer.msisdn + ': ' + dealer.name + ': ' + dealer.nric + ': ' + dealer.plan
      } else {
        this.dealerInfo = 'No dealer selected.'
      }
    },

    search: function (val) {
      clearTimeout(this.searchId)
      this.cancelRequest()
      if (val) {
        this.searchId = setTimeout(() => {
          this.listoldNumber()
        }, 1000)
      }
    },
  },

  methods: {
    async executeChangeDreg () {
      const params = createPostParams({
        action: 'changeDreg',
        payload: {
          idoldNum: this.dealer.id,
          newNumber: this.msisdns,
        },
      })

      try {
        this.loading = true
        await this.$rest.post('postAction.php', params)
        this.$nextTick(() => {
          this.executeAlertType = 'success'
          this.executeAlertMessage = 'Your request to change dreg  was successful.'
          this.msisdns = []
        })
      } catch (e) {
        this.$nextTick(() => {
          this.executeAlertType = 'error'
          this.executeAlertMessage = e.message + ': ' + e.response.data
        })
      } finally {
        this.loading = false
      }
    },

    listoldNumber: async function () {
      this.dealerLoading = true

      const param = createGetParams({ search: this.search })

      try {
        this.source = this.$rest.CancelToken.source()
        Object.assign(param, { cancelToken: this.source.token })
        const response = await this.$rest.get('getOldNumber.php', param)
        this.dealerList = response.data
      } catch (error) {
        if (!this.$rest.isCancel(error)) {
          this.hint = error.message
        }
        this.dealerList = []
      } finally {
        this.dealerLoading = false
      }
    },

    cancelRequest: function () {
      this.dealerLoading = false
      if (this.source) {
        this.source.cancel('Forced cancellation.')
        this.source = null
      }
    },

    deleteItem (item) {
      const index = this.msisdns.indexOf(item)
      this.msisdns.splice(index, 1)
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.addedItem = Object.assign({}, this.defaultItem)
      }, 300)
    },

    async save () {
      const param = createGetParams({
        msisdn: this.addedItem.msisdn,
        dealer: this.dealer.dealer,
      })

      try {
        const response = await this.$rest.get('getNewNumber.php', param)
        this.msisdns = response.data
        this.close()
      } catch (error) {
        console.log(error.response)
        this.alertMessage = error.message
        if (error.response) {
          this.alertMessage += ' - ' + error.response.data
        }
        this.alert = true
      }
    },
  },
}
</script>
